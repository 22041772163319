.navbar {
  background-color: var(--overwatch-orange);
  position: fixed;
  top: 0;
  width: 100%;
  height: 6rem;
  padding: 0 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
}

.logo-container {
  display: flex;
  gap: .5rem;
  justify-content: center;
  align-items: center;
}

.title {
  font-weight: 700;
  color: var(--font-light);
  margin: 0;
}

.nav-items-container {
  display: flex;
  gap: 2rem;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0 2rem;;
  height: 100%;
}

.nav-item {
  position: relative;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .25rem;
  transition: .4s;
  cursor: pointer;

  a {
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--font-light);
    text-decoration: none;
  }

  &:hover::after {
    width: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--font-light);
    transition: .4s;
  }
}

@media screen and (max-width: 749px) {
  .navbar, .nav-items-container {
    width: 100vw;
  }

  .navbar {
    padding: 0 1rem;
    gap: 1rem;
    height: 5rem;
  }

  .nav-items-container {
    padding: 0;
    gap: 1rem;
  }

  .title {
    display: none;
  }

  .nav-items-container {
    justify-content: flex-end;
  }
}
