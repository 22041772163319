.add-match-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header {
  color: var(--font-light);
  margin-bottom: .5rem;
  text-align: left;
}

.close-popup {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.popup-content {
  display: flex;
  gap: 2rem;
}

.input-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  gap: 1rem;
}

@media screen and (max-width: 749px) {
  .input-container {
    gap: .5rem;
    font-size: 12px;
    flex-wrap: wrap;

    > div {
      flex: 1 0 45%;
    }

    > button {
      flex: 1 0 100%;
    }
  }
}
