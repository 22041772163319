.banner {
    background: linear-gradient(#bedaf0 40%, transparent);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5rem;
    margin-top: 5rem;
    transform: translate(-5rem, -8rem);
    width: 100vw;
    height: 70vh;
}

.text-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.5rem;
    max-width: 35%;

    h1 {
      font-weight: bold;
      font-size: 3.5rem;
    }

    p {
      font-size: 1.5rem;
      line-height: normal;
    }

    .emphasis {
      color: var(--overwatch-orange);
    }
}

.buttons {
  display: flex;
  gap: 1rem;
}

.image-block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.banner-image {
    width: 50%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 749px) {
  .banner {
    flex-direction: column;
    gap: 2rem;
    transform: translate(-1rem, -5rem);
    height: 100vh;
    padding: 3rem 1rem;
    overflow: hidden;
  }

  .text-block {
    max-width: 100%;
    gap: .5rem;

    h1 {
      font-size: 2.5rem;
    }

    p {
      font-size: 1.25rem;
    }
  }

  .banner-image {
    width: 90%;
    mask: linear-gradient(to bottom,
    rgba(0,0,0, 1) 0,   rgba(0,0,0, 1) 60%,
    transparent 80%)
  }
}
