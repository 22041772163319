.content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;
  padding: 0 5rem;
}

@media screen and (max-width: 749px) {
  .content-container {
    gap: 3rem;
    padding: 0;
  }

}
