html {
  min-height: 100vh;
}

body {
  background: linear-gradient(135deg, #282c34 10%, #49566e 100%);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  color: var(--font-dark);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --light: #FFF;
  --bg-dark: #282c34;
  --dark-blue: #49566e;
  --font-dark: rgb(52, 52, 62);
  --font-light: rgb(253, 248, 231);
  --font-alt: #949494;
  --alert-red: rgb(158, 40, 40);
  --alert-red-hover: rgb(118, 26, 26);
  --overwatch-orange: #ea6c29;
  --overwatch-orange-dark: #b7490e;
  --overwatch-blue: #3cb3fd;
  --light-grey: rgb(177, 177, 177);
  --link-grey: rgb(212, 212, 212);
  --dark-purple: rgba(17, 0, 32, .5);

  --shadow: 2px 2px 10px 3px rgba(50, 50, 50, 0.8);
  --card-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
  --box-border-radius: 1rem;
}

.x-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--alert-red);
  padding: .25rem;
  border-radius: 50%;
  color: var(--light);
  aspect-ratio: 1/1;
  max-width: 1rem;
  font-size: .75rem;
  transition: .4s;
}

.x-icon:hover {
  cursor: pointer;
    background-color: rgba(17, 0, 32, .3);
}

.gloss-card {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border: 1px solid #ffffff22;
  background-color: #282c34;
  background: linear-gradient(0deg, rgba(40, 44, 52, .5) 50%, rgba(17, 0, 32, .5) 100%);
  box-shadow: 0 7px 20px 5px #00000088;
  border-radius: .7rem;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
}

.chart-card {
  max-width: calc(50% - 1rem);
  flex: 1 0 40%;
}

@media screen and (max-width: 749px) {
  .gloss-card {
    padding: 1rem;
    width: 100%;
  }

  .chart-card {
    max-width: 100%;
  }
}
