.text-with-image {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.reverse {
  flex-direction: row-reverse;
}

.text-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;
  max-width: 40%;

  h1 {
    font-weight: bold;
    font-size: 3.5rem;
    // color: var(--overwatch-orange);
    color: var(--font-light);
  }

  p {
    font-size: 1.5rem;
    line-height: normal;
    color: var(--font-light);
  }
}

@media screen and (max-width: 749px) {
  .text-with-image {
    flex-direction: column;
    width: 100%;
    gap: 2rem;
  }

  .text-block {
    max-width: 100%;
    gap: .5rem;

    h1 {
      font-size: 2rem;
    }

    p {
      font-size: 1.25rem;
    }
  }


}
