.stats-page-container {
  text-align: center;
  background-color: transparent;
  padding: 2rem 0rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border: 1px solid #ffffff22;
  background-color: #282c34;
  background: linear-gradient(0deg, rgba(40, 44, 52, .5) 50%, rgba(17, 0, 32, .5) 100%);
  box-shadow: 0 7px 20px 5px #00000088;
  border-radius: .7rem;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  max-width: calc(50% - 1rem);
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
   > .card-header {
    margin-bottom: 0;
   }
   > .filter-select {
    width: fit-content;
   }
}

.card-header {
  color: var(--font-light);
  margin-bottom: 1.5rem;
  text-align: left;
}

.stats-card {
  min-height: 5rem;
  max-height: fit-content;
}

.stats {
  overflow: auto;
  background-color: var(--light);
  border-radius: var(--bs-border-radius);
  position: relative;
  max-height: 25rem;
}

.match-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: relative;
}

.match-delete {
  position: absolute;
  top: 6px;
  right: .125rem;
}

.no-data {
  color: var(--font-light);
  font-size: 1.5rem;
  background-color: transparent;
  align-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bg-light-green {
  background-color: rgba(161, 235, 161, .5);
}

.bg-light-pink {
  background-color: rgba(242, 160, 172, .5);
}

.match-item {
  border: var(--bs-border-width) solid var(--bs-border-color);
  text-align: center;
  position: relative;
  padding: 2px;
}

@media screen and (max-width: 749px) {
  .stats-page-container {
    flex-direction: column;
  }
}
