.filter-matches-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header {
  color: var(--font-light);
  margin-bottom: .5rem;
  text-align: left;
}

.match-filter-form {
  display: flex;
  width: 100%;
  gap: 1rem;

  > div {
    min-width: 5.5rem;
  }
}

.buttons-container {
  display: flex;
  gap: 1rem;
}

button {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
}

@media screen and (max-width: 749px) {
  .filter-matches-wrapper {
    margin-bottom: 2rem;
  }

  .match-filter-form {
    gap: .5rem;
    font-size: 12px;
    flex-wrap: wrap;

    > div {
      flex: 1 0 45%;
    }

    .buttons-container {
      flex: 1 0 100%;
      display: flex;
      gap: .5rem;

      > button {
        flex: 1 0 45%;
      }
    }
  }
}
